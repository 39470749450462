@charset "UTF-8";

//
// home-approach
//

.home-approach {
  padding-bottom: 260px;
  background-image: url('../../assets/images/home/bg_approach.png');
  background-repeat: no-repeat;
  background-size: 100%;
  background-position: center bottom;
  padding-top: 35px;
  position: relative;
  @include is-palmtop-wider {
    padding-top: 70px;
    padding-bottom: 35%;
    background-image: url('../../assets/images/home/bg_approach_desktop.png');
  }
  &:before {
    @include is-desktop-wider {
      content: '';
      background-image: url('../../assets/images/home/bg_approach_logo_desktop.svg');
      width: 473px;
      height: 473px;
      position: absolute;
      top: 80px;
      left: calc(82% - 240px);
    }
  }
  &__sdgs {
    margin-top: 30px;
    @include is-palmtop-wider {
      margin-top: 65px;
    }
    &__lead {
      max-width: 670px;
      margin-left: auto;
      margin-right: auto;
      position: relative;
      text-align: center;
      @include is-palmtop-wider {
        padding-left: 20px;
        text-align: left;
        display: flex;
        justify-content: center;
      }
      &::before,
      &::after {
        content: '';
        background-image: url('../../assets/images/home/bg_sdgs_lead.png');
        background-size: 28px 48px;
        width: 28px;
        height: 48px;
        background-repeat: no-repeat;
        position: absolute;
        bottom: 10px;
        @include is-palmtop-wider {
          background-size: 48px 81px;
          width: 48px;
          height: 81px;
        }
      }
      &::before {
        left: 0;
        transform: scale(-1,1);
      }
      &::after {
        right: 0;
      }
      &__image {
        display: block;
        margin-left: auto;
        margin-right: auto;
        width: 100%;
        max-width: 214px;
        @include is-palmtop-wider {
          max-width: 240px;
          margin-left: 0;
          margin-right: 15px;
        }
        img {
          max-width: 100%;
          width: 100%;
        }
      }
      &__paragraph {
        display: block;
        margin-top: 30px;
        padding: 0 30px;
        @include is-palmtop-wider {
          margin-left: 15px;
          margin-top: 0;
          padding: 0;
        }
        &__inner {
          font-size: 1.5rem;
          font-weight: 700;
          letter-spacing: .2em;
          line-height: 2.2em;
          color: $color-key;
          display: inline;
          background-color: #fff;
        }
      }
    }
    &__box {
      max-width: 800px;
      background-color: #fff;
      border: 1px dotted $color-key;
      box-sizing: border-box;
      padding: 20px;
      margin: 30px auto 0;
      z-index: 1;
      @include is-palmtop-wider {
        padding: 40px 70px;
        margin: 50px auto 0;
        position: relative;
      }
      &__paragraph {
        &:not(:first-child){
          margin-top: 20px;
          @include is-palmtop-wider {
            margin-top: 40px;
          }
        }
      }
      &__list {
        display: flex;
        flex-wrap: wrap;
        margin-top: 34px;
        margin-left: -10px;
        margin-right: -10px;
        @include is-palmtop-wider {

        }
        &__item {
          display: flex;
          align-items: center;
          padding-left: 10px;
          padding-right: 10px;
          box-sizing: border-box;
          &:nth-child(odd){
            width: 100%;
            @include is-palmtop-wider {
              width: 40%;
            }
          }
          &:nth-child(even){
            width: 100%;
            @include is-palmtop-wider {
              width: 60%;
            }
          }
          &:not(:first-child) {
            margin-top: 15px;
            @include is-palmtop-wider {
              margin-top: 0;
            }
          }
          &:nth-child(n+3){
            @include is-palmtop-wider {
              margin-top: 30px;
            }
          }
          &__number {
            width: 25px;
            height: 25px;
            background-color: #333333;
            color: #ffffff;
            font-family: $font-english;
            text-align: center;
            line-height: 1.9em;
            @include is-palmtop-wider {
              width: 30px;
              height: 30px;
              font-size: 1.9rem;
              line-height: 1.8em;
            }
            &--4 {
              background: #b32a2f;
            }
            &--12 {
              background-color: #c89331;
            }
            &--14 {
              background-color: #3476b5;
            }
            &--17 {
              background-color: #133063;
            }
          }
          &__content {
            margin-left: 10px;
            font-size: 1.4rem;
            line-height: 1.4;
            font-weight: 700;
            letter-spacing: .12em;
          }
        }
      }
    }
  }
  .button {
    &:not(:first-child) {
      margin-top: 30px;
      @include is-palmtop-wider {
        margin-top: 10px;
      }
    }
    &--line {
      &::before {
        margin-bottom: 20px;
        @include is-palmtop-wider {
          margin-bottom: 9px;
        }
      }
    }
  }
}
