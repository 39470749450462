@charset "UTF-8";

//
// メインビジュアル
//

.home-hero {
  background-image: url('../../assets/images/home/bg_hero.jpg');
  background-position: center;
  height: 375px;
  background-size: cover;
  padding-top: 54px;
  box-sizing: border-box;
  @include is-laptop-wider {
    background-image: url('../../assets/images/home/bg_hero_desktop.jpg');
    background-position: center top;
    height: 536px;
  }
  &__heading {
    &__image {
      display: block;
      width: 52vw;
      min-width: 213px;
      margin-left: auto;
      @include is-laptop-wider {
        width: 100%;
        max-width: 876px;
      }
    }
  }
}
