@charset "UTF-8";

//
// サイト全体で共通の設定
//

//
// ブレイクポイント
//

$breakpoint-desktop-min-width: 1050px;
$breakpoint-laptop-min-width: 960px;
$breakpoint-palmtop-min-width: 640px;

//
// 色（deep:濃い pale:淡い）
//

$color-base: #ffffff;
$color-main: #000000;
$color-key: #4b9fe3;
$color-accent: #2e388d;
$color-sub: #f1f9fd;
$color-sub-pale: #e2e2e2;
$color-sub-deep: #aaaaaa;


//
// フォント
//

// 基本のフォント
$font-main: 'Noto Sans JP', sans-serif;

// 英字のフォント
$font-english: 'Odibee Sans', sans-serif;


// html 要素
$font-root: $font-main;

//
// 文字のサイズ
//

$font-size-root-initialize: 62.5%;

// html 要素
$font-size-root-palmtop: 1.4rem;
$font-size-root-laptop: 1.4rem;
$font-size-root-desktop: 1.6rem;

// small 要素
$font-size-small-palmtop: 1.2rem;
$font-size-small-laptop: 1.2rem;
$font-size-small-desktop: 1.2rem;

// sub、sup 要素
$font-size-inferior-palmtop: 1.1rem;
$font-size-inferior-laptop: 1.1rem;
$font-size-inferior-desktop: 1.1rem;

//
// 文字のウェイト
//

$font-weight-root: 400;


//
// container の設定
//

// 幅の上限と下限
$container-max-width: 1280px;
$container-min-width: 320px;

// 左右内側の余白
$container-padded-width-palmtop: 20px;
$container-padded-width-laptop: 20px;
$container-padded-width-desktop: 40px;


//
// アニメーションの設定
//

$animation: all .2s;
$animation-slow: all .5s;
