.home-hero {
  background-image: url("../../assets/images/home/bg_hero.jpg");
  background-position: center;
  height: 375px;
  background-size: cover;
  padding-top: 54px;
  box-sizing: border-box;
}

@media screen and (min-width: 960px) {
  .home-hero {
    background-image: url("../../assets/images/home/bg_hero_desktop.jpg");
    background-position: center top;
    height: 536px;
  }
}

.home-hero__heading__image {
  display: block;
  width: 52vw;
  min-width: 213px;
  margin-left: auto;
}

@media screen and (min-width: 960px) {
  .home-hero__heading__image {
    width: 100%;
    max-width: 876px;
  }
}

.home-approach {
  padding-bottom: 260px;
  background-image: url("../../assets/images/home/bg_approach.png");
  background-repeat: no-repeat;
  background-size: 100%;
  background-position: center bottom;
  padding-top: 35px;
  position: relative;
}

@media screen and (min-width: 640px) {
  .home-approach {
    padding-top: 70px;
    padding-bottom: 35%;
    background-image: url("../../assets/images/home/bg_approach_desktop.png");
  }
}

@media screen and (min-width: 1050px) {
  .home-approach:before {
    content: '';
    background-image: url("../../assets/images/home/bg_approach_logo_desktop.svg");
    width: 473px;
    height: 473px;
    position: absolute;
    top: 80px;
    left: calc(82% - 240px);
  }
}

.home-approach__sdgs {
  margin-top: 30px;
}

@media screen and (min-width: 640px) {
  .home-approach__sdgs {
    margin-top: 65px;
  }
}

.home-approach__sdgs__lead {
  max-width: 670px;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  text-align: center;
}

@media screen and (min-width: 640px) {
  .home-approach__sdgs__lead {
    padding-left: 20px;
    text-align: left;
    display: flex;
    justify-content: center;
  }
}

.home-approach__sdgs__lead::before, .home-approach__sdgs__lead::after {
  content: '';
  background-image: url("../../assets/images/home/bg_sdgs_lead.png");
  background-size: 28px 48px;
  width: 28px;
  height: 48px;
  background-repeat: no-repeat;
  position: absolute;
  bottom: 10px;
}

@media screen and (min-width: 640px) {
  .home-approach__sdgs__lead::before, .home-approach__sdgs__lead::after {
    background-size: 48px 81px;
    width: 48px;
    height: 81px;
  }
}

.home-approach__sdgs__lead::before {
  left: 0;
  transform: scale(-1, 1);
}

.home-approach__sdgs__lead::after {
  right: 0;
}

.home-approach__sdgs__lead__image {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  max-width: 214px;
}

@media screen and (min-width: 640px) {
  .home-approach__sdgs__lead__image {
    max-width: 240px;
    margin-left: 0;
    margin-right: 15px;
  }
}

.home-approach__sdgs__lead__image img {
  max-width: 100%;
  width: 100%;
}

.home-approach__sdgs__lead__paragraph {
  display: block;
  margin-top: 30px;
  padding: 0 30px;
}

@media screen and (min-width: 640px) {
  .home-approach__sdgs__lead__paragraph {
    margin-left: 15px;
    margin-top: 0;
    padding: 0;
  }
}

.home-approach__sdgs__lead__paragraph__inner {
  font-size: 1.5rem;
  font-weight: 700;
  letter-spacing: .2em;
  line-height: 2.2em;
  color: #4b9fe3;
  display: inline;
  background-color: #fff;
}

.home-approach__sdgs__box {
  max-width: 800px;
  background-color: #fff;
  border: 1px dotted #4b9fe3;
  box-sizing: border-box;
  padding: 20px;
  margin: 30px auto 0;
  z-index: 1;
}

@media screen and (min-width: 640px) {
  .home-approach__sdgs__box {
    padding: 40px 70px;
    margin: 50px auto 0;
    position: relative;
  }
}

.home-approach__sdgs__box__paragraph:not(:first-child) {
  margin-top: 20px;
}

@media screen and (min-width: 640px) {
  .home-approach__sdgs__box__paragraph:not(:first-child) {
    margin-top: 40px;
  }
}

.home-approach__sdgs__box__list {
  display: flex;
  flex-wrap: wrap;
  margin-top: 34px;
  margin-left: -10px;
  margin-right: -10px;
}

.home-approach__sdgs__box__list__item {
  display: flex;
  align-items: center;
  padding-left: 10px;
  padding-right: 10px;
  box-sizing: border-box;
}

.home-approach__sdgs__box__list__item:nth-child(odd) {
  width: 100%;
}

@media screen and (min-width: 640px) {
  .home-approach__sdgs__box__list__item:nth-child(odd) {
    width: 40%;
  }
}

.home-approach__sdgs__box__list__item:nth-child(even) {
  width: 100%;
}

@media screen and (min-width: 640px) {
  .home-approach__sdgs__box__list__item:nth-child(even) {
    width: 60%;
  }
}

.home-approach__sdgs__box__list__item:not(:first-child) {
  margin-top: 15px;
}

@media screen and (min-width: 640px) {
  .home-approach__sdgs__box__list__item:not(:first-child) {
    margin-top: 0;
  }
}

@media screen and (min-width: 640px) {
  .home-approach__sdgs__box__list__item:nth-child(n+3) {
    margin-top: 30px;
  }
}

.home-approach__sdgs__box__list__item__number {
  width: 25px;
  height: 25px;
  background-color: #333333;
  color: #ffffff;
  font-family: "Odibee Sans", sans-serif;
  text-align: center;
  line-height: 1.9em;
}

@media screen and (min-width: 640px) {
  .home-approach__sdgs__box__list__item__number {
    width: 30px;
    height: 30px;
    font-size: 1.9rem;
    line-height: 1.8em;
  }
}

.home-approach__sdgs__box__list__item__number--4 {
  background: #b32a2f;
}

.home-approach__sdgs__box__list__item__number--12 {
  background-color: #c89331;
}

.home-approach__sdgs__box__list__item__number--14 {
  background-color: #3476b5;
}

.home-approach__sdgs__box__list__item__number--17 {
  background-color: #133063;
}

.home-approach__sdgs__box__list__item__content {
  margin-left: 10px;
  font-size: 1.4rem;
  line-height: 1.4;
  font-weight: 700;
  letter-spacing: .12em;
}

.home-approach .button:not(:first-child) {
  margin-top: 30px;
}

@media screen and (min-width: 640px) {
  .home-approach .button:not(:first-child) {
    margin-top: 10px;
  }
}

.home-approach .button--line::before {
  margin-bottom: 20px;
}

@media screen and (min-width: 640px) {
  .home-approach .button--line::before {
    margin-bottom: 9px;
  }
}

.home-company {
  padding-top: 40px;
  padding-bottom: 35px;
}

@media screen and (min-width: 640px) {
  .home-company {
    padding-top: 100px;
    padding-bottom: 70px;
  }
}

.home-company__main {
  background-image: url("../../assets/images/home/bg_message.jpg");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: left 70px;
}

@media screen and (min-width: 640px) {
  .home-company__main {
    background-image: url("../../assets/images/home/bg_message_desktop.jpg");
    background-position: left 15px;
  }
}

.home-company__main__box {
  max-width: 628px;
  padding-top: calc(70% + 100px);
  padding-right: 20px;
}

@media screen and (min-width: 640px) {
  .home-company__main__box {
    padding: 5vw 0 5vw calc(60% - 150px);
  }
}

@media screen and (min-width: 960px) {
  .home-company__main__box {
    padding: 5vw 0 5vw calc(50% - 150px);
  }
}

.home-company__main__box__author {
  text-align: right;
  margin-top: 30px;
}

@media screen and (min-width: 640px) {
  .home-company__main__box__author {
    margin-top: 50px;
  }
}

.home-company__main__box__author__position {
  display: inline-block;
  margin-right: 20px;
}

.home-company__main__box__author__name {
  display: inline-block;
  font-size: 1.6rem;
}

@media screen and (min-width: 640px) {
  .home-company__main__box__author__name {
    font-size: 1.9rem;
  }
}

.home-company__policy {
  background-color: #f1f9fd;
  position: relative;
  margin-top: 100px;
  padding: 40px 20px;
}

@media screen and (min-width: 640px) {
  .home-company__policy {
    margin-top: 60px;
    padding: 60px 60px 60px 60px;
  }
}

@media screen and (min-width: 960px) {
  .home-company__policy {
    padding: 60px 110px 60px 110px;
  }
}

.home-company__policy::before {
  display: block;
  content: '';
  height: 44px;
  width: 2px;
  margin-left: auto;
  margin-right: auto;
  background-color: #4b9fe3;
  border-radius: 1px;
  margin-bottom: 9px;
  transition: all 0.2s;
  position: absolute;
  top: -72px;
  left: 50%;
}

@media screen and (min-width: 640px) {
  .home-company__policy::before {
    top: -52px;
  }
}

.home-company__policy__columns {
  text-align: center;
}

@media screen and (min-width: 640px) {
  .home-company__policy__columns {
    text-align: left;
    display: flex;
    margin-left: -30px;
    margin-right: -30px;
  }
}

@media screen and (min-width: 960px) {
  .home-company__policy__columns {
    margin-left: -75px;
    margin-right: -75px;
  }
}

@media screen and (min-width: 640px) {
  .home-company__policy__columns__column {
    width: 50%;
    box-sizing: border-box;
    padding-left: 30px;
    padding-right: 30px;
  }
}

@media screen and (min-width: 960px) {
  .home-company__policy__columns__column {
    padding-left: 75px;
    padding-right: 75px;
  }
}

.home-company__policy__columns__column:not(:first-child) {
  margin-top: 40px;
}

@media screen and (min-width: 640px) {
  .home-company__policy__columns__column:not(:first-child) {
    margin-top: 0;
  }
}

.home-company .button:not(:first-child) {
  margin-top: 10px;
}

.home-heading {
  color: #4b9fe3;
  font-size: 2.0rem;
  letter-spacing: .3em;
  font-weight: 500;
  text-align: center;
}

@media screen and (min-width: 960px) {
  .home-heading {
    font-size: 2.8rem;
  }
}

@media screen and (min-width: 640px) {
  .home-heading--left {
    text-align: left;
  }
}

.home-heading::after {
  content: attr(data-en-text);
  font-family: "Odibee Sans", sans-serif;
  color: #2e388d;
  font-size: 1.4rem;
  letter-spacing: .06em;
  display: block;
  margin-top: 10px;
}
