@charset "UTF-8";

//
// home-company
//

.home-company {
  padding-top: 40px;
  padding-bottom: 35px;
  @include is-palmtop-wider {
    padding-top: 100px;
    padding-bottom: 70px;
  }
  &__main {
    background-image: url('../../assets/images/home/bg_message.jpg');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: left 70px;
    @include is-palmtop-wider {
      background-image: url('../../assets/images/home/bg_message_desktop.jpg');
      background-position: left 15px;
    }
    &__box {
      max-width: 628px;
      padding-top: calc(70% + 100px);
      padding-right: 20px;
      @include is-palmtop-wider {
        padding: 5vw 0 5vw calc(60% - 150px);
      }
      @include is-laptop-wider {
        padding: 5vw 0 5vw calc(50% - 150px);
      }
      &__author {
        text-align: right;
        margin-top: 30px;
        @include is-palmtop-wider {
          margin-top: 50px;
        }
        &__position {
          display: inline-block;
          margin-right: 20px;
        }
        &__name {
          display: inline-block;
          font-size: 1.6rem;
          @include is-palmtop-wider {
            font-size: 1.9rem;
          }
        }
      }
    }
  }
  &__policy {
    background-color: $color-sub;
    position: relative;
    margin-top: 100px;
    padding: 40px 20px;
    @include is-palmtop-wider {
      margin-top: 60px;
      padding: 60px 60px 60px 60px;
    }
    @include is-laptop-wider {
      padding: 60px 110px 60px 110px;
    }
    &::before {
      display: block;
      content: '';
      height: 44px;
      width: 2px;
      margin-left: auto;
      margin-right: auto;
      background-color: $color-key;
      border-radius: 1px;
      margin-bottom: 9px;
      transition: $animation;
      position: absolute;
      top: -72px;
      left: 50%;
      @include is-palmtop-wider {
        top: -52px;
      }
    }
    &__columns {
      text-align: center;
      @include is-palmtop-wider {
        text-align: left;
        display: flex;
        margin-left: -30px;
        margin-right: -30px;
      }
      @include is-laptop-wider {
        margin-left: -75px;
        margin-right: -75px;
      }
      &__column {
        @include is-palmtop-wider {
          width: 50%;
          box-sizing: border-box;
          padding-left: 30px;
          padding-right: 30px;
        }
        @include is-laptop-wider {
          padding-left: 75px;
          padding-right: 75px;
        }
        &:not(:first-child){
          margin-top: 40px;
          @include is-palmtop-wider {
            margin-top: 0;
          }
        }
      }
    }
  }
  .button:not(:first-child) {
    margin-top: 10px;
  }
}
