@charset "UTF-8";

//
// home-heading
//

.home-heading {
  color: $color-key;
  font-size: 2.0rem;
  letter-spacing: .3em;
  font-weight: 500;
  text-align: center;
  @include is-laptop-wider {
    font-size: 2.8rem;
  }
  &--left {
    @include is-palmtop-wider {
      text-align: left;
    }
  }
  &::after {
    content: attr(data-en-text);
    font-family: $font-english;
    color: $color-accent;
    font-size: 1.4rem;
    letter-spacing: .06em;
    display: block;
    margin-top: 10px;
  }
}
