@charset "UTF-8";

//
// サイト全体で共通のミックスイン
//


// メディアクエリ用

@mixin is-desktop-narrower {
  @media screen and (max-width: $breakpoint-laptop-min-width - 1) {
    @content;
  }
}

@mixin is-palmtop-wider {
  @media screen and (min-width: $breakpoint-palmtop-min-width) {
    @content;
  }
}

@mixin is-laptop-wider {
  @media screen and (min-width: $breakpoint-laptop-min-width) {
    @content;
  }
}

@mixin is-desktop-wider {
  @media screen and (min-width: $breakpoint-desktop-min-width) {
    @content;
  }
}
